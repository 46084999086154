import { useNavigate } from "react-router-dom";
import Title from "../../../components/Title.js/Title";
import styles from "./FichesCreate.module.scss"
import axios from "axios";
import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/AdminSideBar/AdminSideBar";
import Notification from "../../../components/Notification/Notification";

import { API_URL } from "../../../conf";

// import Title from "../../components/Title.js/Title";
// import { useState } from "react";
// import CardMember from "../../components/CardMember/CardMember";
// import Notification from "../../components/Notification/Notification";
//const API_URL = "http://localhost:3001/"
//const API_URL = "https://app.pictageek.fr/";


function FichesCreate() {

    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [naissance, setNaissance] = useState('')
    const [telephone, setTelephone] = useState('')
    const [mail, setMail] = useState('')
    const [contact_secours, setContact_secours] = useState('')
    const [pole, setPole] = useState('cosplay')
    const [planning, setPlanning] = useState('')
    const [taille_vetement, setTaille_vetement] = useState('4xl')
    const [regime_alimentaire, setRegime_alimentaire] = useState('')

    const [exp_nom_stand, setExpNomStand] = useState('')
    const [exp_numero_stand, setExpNumeroStand] = useState('')
    const [exp_nom_responsable, setExpNomResponsable] = useState('')
    const [exp_prenom_responsable, setExpPrenomResponsable] = useState('')
    const [exp_telephone, setExpTelephone] = useState('')
    const [exp_taille_stand, setExpTailleStand] = useState('')
    const [exp_type_stand, setExpTypeStand] = useState('')
    const [exp_nombre_pass_exposant, setExpNombrePassExposant] = useState('')
    

    axios.defaults.withCredentials = true;
    const navigate = useNavigate()
    useEffect(() => {
        axios.get(`${API_URL}api/CheckIsAdmin`) //vérifie si l'utilisateur a la perm niveau 1.
        .then(result => {
          //console.log(result);
          if(result.data.status !== "Success") {
            navigate('/home')
          } else {
            // if(result.data.permission < 1) { //si non membre ni admin. Donc que utilisateur.
            //     navigate('/home') //on revoir vers la page Home.
            // }
          }
        })
        .catch(err => console.log(err))
    }, [])

    const [notification, setNotification] = useState(null);
    const [issuccess, setIsSuccess] = useState(true);
  // const [notiflink, setnotifLink] = useState(null);
  // const [notiflinktext, setnotifLinktext] = useState(null);
  const [notificationVisible, setNotificationVisible] = useState(false);

  const showNotification = (text/*, lnk, lnktxt*/) => {
    setNotification(text);
    //setnotifLink(lnk);
    //setnotifLinktext(lnktxt);
    setNotificationVisible(true);
  };

  const closeNotification = () => {
    //setNotification(null);
    setNotificationVisible(false);
  };

  //handlers

  const handleNomChange = (e) => {
    setNom(e.target.value);
  };

  const handlePrenomChange = (e) => {
    setPrenom(e.target.value);
  };

  const handleNaissanceChange = (e) => {
    setNaissance(e.target.value);
  };

  const handleTelephoneChange = (e) => {
    setTelephone(e.target.value);
  };

  const handleMailChange = (e) => {
    setMail(e.target.value);
  };

  const handleContactSecoursChange = (e) => {
    setContact_secours(e.target.value);
  };

  const handlePoleChange = (e) => {
    setPole(e.target.value);
  };

  const handlePlanningChange = (e) => {
    setPlanning(e.target.value);
  };

  const handleTailleVetementChange = (e) => {
    setTaille_vetement(e.target.value);
  };

  const handleRegimeChange = (e) => {
    setRegime_alimentaire(e.target.value);
  };

  
    const handleExpNomStandChange = (e) => {
      setExpNomStand(e.target.value)
    };

    const handleExpNumeroStandChange = (e) => {
      setExpNumeroStand(e.target.value)
    };

    const handleExpNomResponsableChange = (e) => {
      setExpNomResponsable(e.target.value)
    };

    const handleExpPrenomResponsableChange = (e) => {
      setExpPrenomResponsable(e.target.value)
    };

    const handleExpTelephoneChange = (e) => {
      setExpTelephone(e.target.value)
    };

    const handleExpTailleStandChange = (e) => {
      setExpTailleStand(e.target.value)
    };

    const handleExpTypeStandChange = (e) => {
      setExpTypeStand(e.target.value)
    };
    
    const handleExpNombrePassExposantChange = (e) => {
      setExpNombrePassExposant(e.target.value)
    };







    const handleSubmit = (e) => {
        //console.log("Submitted");
        //showNotification('Fiche staff créee');
        e.preventDefault();
          axios.post(`${API_URL}api/fichecreate`, {type:"Staff", nom:nom, prenom:prenom, naissance:naissance, telephone:telephone, mail:mail, contact_secours:contact_secours, pole:pole, planning:planning, taille_vetement:taille_vetement, regime_alimentaire:regime_alimentaire})
          .then(result => {
            if(result.data === "Success") {
                //console.log("ça fonctionne");
                //console.log(result);
                setIsSuccess(true);
                showNotification('Fiche staff créee');

                setNom("");
                setPrenom("");
                setNaissance("");
                setTelephone("");
                setMail("");
                setContact_secours("");
                setPole("cosplay");
                setPlanning("");
                setTaille_vetement("xs");
                setRegime_alimentaire("");

            } else {
                //console.log("ça fonctionne pas");
                //console.log(result);
                setIsSuccess(false);
                showNotification('Une erreur est survenue dans la création de la fiche.');
            }
            
          })
          .catch(err => {
              setIsSuccess(false);
              showNotification('Une erreur est survenue dans la création de la fiche.');
              console.log(err)
          })
    };

    const handleFicheSubmit = (e) => {
      
      //console.log("Submitted fiche");
      e.preventDefault();

    
      axios.post(`${API_URL}api/fichecreate`, {type:"Exposant", nom_stand:exp_nom_stand, numero_stand:exp_numero_stand, nom_responsable:exp_nom_responsable, prenom_responsable:exp_prenom_responsable, telephone:exp_telephone, taille_stand:exp_taille_stand, type_stand:exp_type_stand, nombre_pass_exposant:exp_nombre_pass_exposant})
      .then(result => {
        if(result.data === "Success") {
            //console.log("ça fonctionne");
            //console.log(result);
            setIsSuccess(true);
            showNotification('Fiche Exposant créee');

            setExpNomStand("")
            setExpNumeroStand("")
            setExpNomResponsable("")
            setExpPrenomResponsable("")
            setExpTelephone("")
            setExpTailleStand("")
            setExpTypeStand("")
            setExpNombrePassExposant("")

        } else {
            //console.log("ça fonctionne pas");
            //console.log(result);
            setIsSuccess(false);
            showNotification('Une erreur est survenue dans la création de la fiche.');
        }
        
      })
      .catch(err => {
        setIsSuccess(false);
        showNotification('Une erreur est survenue dans la création de la fiche.');
        console.log(err)
      })
    }


    return (
      
        <div className={styles.FichesCreate}>
          <div className={styles.title}>
            <Title text={"Admin Panel"}/>
          </div>
          <div className={styles.sidebar}>
            <AdminSideBar />
          </div>
          <div className={styles.formfichecreate}>


            <form onSubmit={handleSubmit}>
            <div className={styles.formTitle}>Fiche Staff</div>
                <div class="rendered-form">
                    <div class="formbuilder-text form-group field-nom">
                        <label for="nom" class="formbuilder-text-label">Nom</label>
                        <input type="text" class="form-control" name="nom" access="false" id="nom" value={nom} onChange={handleNomChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-prenom">
                        <label for="prenom" class="formbuilder-text-label">Prénom</label>
                        <input type="text" class="form-control" name="prenom" access="false" id="prenom" value={prenom} onChange={handlePrenomChange} required />
                    </div>
                    <div class="formbuilder-date form-group field-naissance">
                        <label for="naissance" class="formbuilder-date-label">Date de naissance</label>
                        <input type="date" class="form-control" name="naissance" access="false" id="naissance" value={naissance} onChange={handleNaissanceChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-telephone">
                        <label for="telephone" class="formbuilder-text-label">Téléphone</label>
                        <input type="tel" class="form-control" name="telephone" access="false" id="telephone" value={telephone} onChange={handleTelephoneChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-mail">
                        <label for="mail" class="formbuilder-text-label">E-Mail</label>
                        <input type="text" class="form-control" name="mail" access="false" id="mail" value={mail} onChange={handleMailChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-contact_secours">
                        <label for="contact_secours" class="formbuilder-text-label">Contact de secours</label>
                        <input type="text" class="form-control" name="contact_secours" access="false" id="contact_secours" value={contact_secours} onChange={handleContactSecoursChange} required />
                    </div>
                    <div class="formbuilder-select form-group field-pole">
                        <label for="pole" class="formbuilder-select-label">Pôle</label>
                        <select class="form-control" name="pole" id="pole" onChange={handlePoleChange} required >
                            <option value="billeterie" selected={pole === "billeterie" ? "true" : "false"} id="pole-1">Billeterie</option>
                            <option value="gestionpublic" selected={pole === "gestionpublic" ? "true" : "false"} id="pole-2">Gestion Public</option>
                            <option value="invites" selected={pole === "invites" ? "true" : "false"} id="pole-3">Invités</option>
                            <option value="invitesint" selected={pole === "invitesint" ? "true" : "false"} id="pole-4">Invités Int.</option>
                            <option value="mangaka" selected={pole === "mangaka" ? "true" : "false"} id="pole-5">Mangaka</option>
                            <option value="catering" selected={pole === "catering" ? "true" : "false"} id="pole-6">Catering</option>
                            <option value="standconsigne" selected={pole === "standconsigne" ? "true" : "false"} id="pole-7">Stand/Consigne</option>
                            <option value="bar" selected={pole === "bar" ? "true" : "false"} id="pole-8">Bar</option>
                            <option value="exposant" selected={pole === "exposant" ? "true" : "false"} id="pole-9">Exposant</option>
                            <option value="grandscene" selected={pole === "grandscene" ? "true" : "false"} id="pole-10">Grand Scène</option>
                            <option value="petitescene" selected={pole === "petitescene" ? "true" : "false"} id="pole-11">Petite Scène</option>
                            <option value="zonesport" selected={pole === "zonesport" ? "true" : "false"} id="pole-12">Zone Sport</option>
                            <option value="animation" selected={pole === "animation" ? "true" : "false"} id="pole-13">Animation</option>
                            <option value="photo" selected={pole === "photo" ? "true" : "false"} id="pole-14">Photo</option>
                            <option value="media" selected={pole === "media" ? "true" : "false"} id="pole-15">Media</option>
                            <option value="cosplay" selected={pole === "cosplay" ? "true" : "false"} id="pole-16">Cosplay</option>
                        </select>
                    </div>
                    {/* <div class="formbuilder-text form-group field-planning">
                        <label for="planning" class="formbuilder-text-label">Planning</label>
                        <input type="text" class="form-control" name="planning" access="false" id="planning" value={planning} onChange={handlePlanningChange} required />
                    </div> */}
                    <div class="formbuilder-select form-group field-taille_vetement">
                        <label for="taille_vetement" class="formbuilder-select-label">Taille de vêtement</label>
                        <select class="form-control" name="taille_vetement" id="taille_vetement" onChange={handleTailleVetementChange} required >
                            <option value="xs" selected={taille_vetement === "xs" ? "true" : "false"} id="taille_vetement-0">XS</option>
                            <option value="s" selected={taille_vetement === "s" ? "true" : "false"} id="taille_vetement-1">S</option>
                            <option value="m" selected={taille_vetement === "m" ? "true" : "false"} id="taille_vetement-2">M</option>
                            <option value="l" selected={taille_vetement === "l" ? "true" : "false"} id="taille_vetement-3">L</option>
                            <option value="xl" selected={taille_vetement === "xl" ? "true" : "false"} id="taille_vetement-4">XL</option>
                            <option value="xxl" selected={taille_vetement === "xxl" ? "true" : "false"} id="taille_vetement-5">XXL</option>
                            <option value="3xl" selected={taille_vetement === "3xl" ? "true" : "false"} id="taille_vetement-6">3XL</option>
                            <option value="4xl" selected={taille_vetement === "4xl" ? "true" : "false"} id="taille_vetement-7">4XL</option>
                        </select>
                    </div>
                    <div class="formbuilder-text form-group field-regime">
                        <label for="regime" class="formbuilder-text-label">Régime alimentaire</label>
                        <input type="text" class="form-control" name="regime" access="false" id="regime" value={regime_alimentaire} onChange={handleRegimeChange} required />
                    </div>
                    <div class="formbuilder-button form-group field-regime">
                        <button type="submit" class="form-control" name="button" id="button">
                            Créer la fiche
                        </button>
                    </div>
                </div>
            </form>
            
            <form onSubmit={handleFicheSubmit}>
            <div className={styles.formTitle}>Fiche Exposant</div>
                <div class="rendered-form">
                    <div class="formbuilder-text form-group field-nom">
                        <label for="exp_nom_stand" class="formbuilder-text-label">Nom du Stand</label>
                        <input type="text" class="form-control" name="exp_nom_stand" access="false" id="exp_nom_stand" value={exp_nom_stand} onChange={handleExpNomStandChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-prenom">
                        <label for="exp_numero_stand" class="formbuilder-text-label">Numéro du Stand</label>
                        <input type="text" class="form-control" name="exp_numero_stand" access="false" id="exp_numero_stand" value={exp_numero_stand} onChange={handleExpNumeroStandChange} required />
                    </div>
                    <div class="formbuilder-date form-group field-naissance">
                        <label for="exp_nom_responsable" class="formbuilder-date-label">Nom du responsable</label>
                        <input type="text" class="form-control" name="exp_nom_responsable" access="false" id="exp_nom_responsable" value={exp_nom_responsable} onChange={handleExpNomResponsableChange} required />
                    </div>
                    <div class="formbuilder-date form-group field-naissance">
                        <label for="exp_prenom_responsable" class="formbuilder-date-label">Prénom du responsable</label>
                        <input type="text" class="form-control" name="exp_prenom_responsable" access="false" id="exp_prenom_responsable" value={exp_prenom_responsable} onChange={handleExpPrenomResponsableChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-telephone">
                        <label for="exp_telephone" class="formbuilder-text-label">Téléphone</label>
                        <input type="tel" class="form-control" name="exp_telephone" access="false" id="exp_telephone" value={exp_telephone} onChange={handleExpTelephoneChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-contact_secours">
                        <label for="exp_taille_stand" class="formbuilder-text-label">Taille du Stand</label>
                        <input type="text" class="form-control" name="exp_taille_stand" access="false" id="exp_taille_stand" value={exp_taille_stand} onChange={handleExpTailleStandChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-contact_secours">
                        <label for="exp_type_stand" class="formbuilder-text-label">Type du Stand</label>
                        <input type="text" class="form-control" name="exp_type_stand" access="false" id="exp_type_stand" value={exp_type_stand} onChange={handleExpTypeStandChange} required />
                    </div>
                    <div class="formbuilder-text form-group field-contact_secours">
                        <label for="exp_nombre_pass_exposant" class="formbuilder-text-label">Nombre pass exposant</label>
                        <input type="text" class="form-control" name="exp_nombre_pass_exposant" access="false" id="exp_nombre_pass_exposant" value={exp_nombre_pass_exposant} onChange={handleExpNombrePassExposantChange} required />
                    </div>
                    
                    
                    <div class="formbuilder-button form-group field-regime">
                        <button type="submit" class="form-control" name="button" id="button">
                            Créer la fiche
                        </button>
                    </div>
                </div>
            </form>

          </div>
          {notification && (
            <Notification
            message={notification}
            duration={5000} // Durée en millisecondes (5 secondes dans cet exemple)
            onClose={closeNotification}
            visible={notificationVisible}
            success={issuccess}
            // link={notiflink}
            // linktext={notiflinktext}
            />
            )}
        </div>
    );

}

export default FichesCreate;
