import axios from "axios";
import { API_URL } from "../conf";
//const API_URL = "http://localhost:3001/"
//const API_URL = "https://app.pictageek.fr/";

export async function getMembersById(id, token) {
    //console.log(token)
    try {
        const response = axios.get(`${API_URL}api/getdocument/${id}`, {
            headers: {
            "Authorization": "Bearer" + token
        }});
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function getAllRelevantMembers(info) {
    //console.log(token)
    try {
        const response = await axios.get(`${API_URL}api/getrelevantdocument/${info}`, {
            headers: {
            "Authorization": "Bearer" + ""
        }});
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function getAllCardsMember() {
    //console.log(token)
    try {
        const response = await axios.get(`${API_URL}api/getallcards`, {
            headers: {
            "Authorization": "Bearer" + ""
        }});
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function getAllAccounts() {
    //console.log(token)
    try {
        const response = await axios.get(`${API_URL}api/getAllAccounts`, {
            headers: {
            "Authorization": "Bearer" + ""
        }});
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}



//obsolète - ne pas utiliser
export async function loginUser(user, pass) {
    try {
        const response = axios.get(`${API_URL}api/users/login/${user}&${pass}`);
        /*, {
            headers: {
                "Authorization": "Bearer" + token
            }}*/
        return response;
    } catch (error) {
        console.log(error);
    }
}