import axios from "axios";
import { API_URL } from "../conf";
//const API_URL = "http://localhost:3001/"
//const API_URL = "https://app.pictageek.fr/";

//récupère toutes les fiches des QR-Codes
export async function getAllFicheQR() {
    //console.log(token)
    try {
        const response = await axios.get(`${API_URL}api/getAllFicheQR`, {
            headers: {
            "Authorization": "Bearer" + ""
        }});
        return response.data; //"response" retourne toute la promise axios. .data donne les données
    } catch (error) {
        console.log(error);
        throw error;
    }
}

//récupère toutes les fiches des QR-Codes-LeSAviezVous
export async function getAllFicheQRLSV() {
    //console.log(token)
    try {
        const response = await axios.get(`${API_URL}api/getAllFicheQRLSV`, {
            headers: {
            "Authorization": "Bearer" + ""
        }});
        return response.data; //"response" retourne toute la promise axios. .data donne les données
    } catch (error) {
        console.log(error);
        throw error;
    }
}


//récupère tous les QR trouver par l'utilisateur en cours.
export async function getFoundedQR() {
    //console.log(token)
    try {
        const response = await axios.get(`${API_URL}api/getAllAccounts`, {
            headers: {
            "Authorization": "Bearer" + ""
        }});
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}