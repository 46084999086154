import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import Title from "../../../components/Title.js/Title";
import styles from "./scanqrsportslsv.module.scss";
import { API_URL } from "../../../conf";
import { getAllFicheQRLSV } from "../../../api/game";

function ScanQRSportLSV() {
  const { urlid } = useParams();
  const navigate = useNavigate();
  const [dbUser, setdbUser] = useState(null);
  const [allQRFiches, setAllQRFiches] = useState([]);
  const [foundQR, setFoundQR] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_URL}api/getActualDBuser`);
        if (result.data !== "invalid") {
          setdbUser(result.data);
        } else {
          console.log("Non connecté");
          navigate('/connection');
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchAllQRFiches = async () => {
      try {
        const fiches = await getAllFicheQRLSV();
        setAllQRFiches(fiches);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    fetchAllQRFiches();
  }, [navigate]);

  useEffect(() => {
    if (dbUser && allQRFiches.length > 0) {
      findQRCode();
    }
  }, [dbUser, allQRFiches, urlid]);

  const findQRCode = () => {
    const qrCode = allQRFiches.find(item => item.urlid === urlid);

    if (urlid === "1") {
      setFoundQR("Le Savais-Tu ? Ça marche pas comme ça, espèce de petit rigolo. 👀");
      return;
    }

    if (!qrCode) {
      setFoundQR("Le code est invalide.");
      return;
    }

    if (dbUser.qrfound_sports_lsv.includes(qrCode.gameid)) {
      setFoundQR("Ce code a déjà été scanné.");
      return;
    }

    // Ajouter le code trouvé à la base de données
    const addQR = async () => {
      try {
        const res = await axios.put(`${API_URL}api/addQRLSV`, {
          accountId: dbUser._id,
          username: dbUser.username,
          gameid: qrCode.gameid,
        });

        if (res.data.status === "Success") {
          console.log("Ajout du QR code LSV effectué avec succès.");
          console.log("DATE: " + Date.now())
        } else {
          console.log("Impossible d'ajouter le QR code LSV.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    addQR();

    setFoundQR(
      <div>
        <p>Le Saviez-Vous ?</p>
        <ul>
          <li key={qrCode.gameid} className={styles.list}>
            <>
              <img
                src={qrCode.image || 'img/notfound.png'}
                alt={qrCode.pays}
                className={styles.imgqr}
              />
              <p className={styles.listtitle}>
                {qrCode.gameid} - {qrCode.pays}
              </p>
              <p className={styles.listdesc}>{qrCode.description}</p>
            </>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className={styles.scanqrsportlsv}>
      {foundQR}
    </div>
  );
}

export default ScanQRSportLSV;
