import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../../components/Title.js/Title";
import styles from "./Game2024.module.scss";
import axios from "axios";
import { getAllFicheQR, getFoundedQR } from "../../../api/game";
import { API_URL } from "../../../conf";

//const API_URL = "http://localhost:3001/";

function Game2024() {

  const [dbUser, setdbUser] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [numberOfFounded, setNumberOfFounder] = useState(0);
  const [allQRFiches, setAllQRFiches] = useState([]);
  const navigate = useNavigate();

  // Effect pour charger dbUser à chaque rendu
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_URL}api/getActualDBuser`);
        //console.log(result);
        if (result.data !== "invalid") {
          setdbUser(result.data);
          //console.log(result.data);
          //console.log(result.data.qrfound_sports);
        } else {
          console.log("Non connecté");
          navigate('/connection');
        }
      } catch (error) {
        console.log(error);
      }
    };
    //console.log("FETCHING NEW DBUSER")
    fetchData();
  }, [navigate]);

  // Effect pour charger les fiches lorsque dbUser.qrfound_sports change
  useEffect(() => {
    const fetchFiches = async () => {
      try {
        let fiches = await getAllFicheQR();
        setAllQRFiches(fiches);
        //console.log(fiches);
        //console.log(dbUser);
        let filteredItems = Array.isArray(fiches) ? fiches.filter(item => dbUser.qrfound_sports.includes(item.gameid)) : [];
        //console.log("result: " + filteredItems);
        setFilteredItems(filteredItems);
      } catch (error) {
        console.log(error);
      }
    };

    fetchFiches();
  }, [dbUser.qrfound_sports]);

  return (
    // <div className={styles.Game2024}>
    //   <Title text={"Jeu Sportifs"} />
    //   <div>
    //     <ul>
    //       {/*filteredItems*/}
    //       {allQRFiches.map(item => (
    //         <li key={item.gameid} className={styles.list}>
    //           <img src={item.image || 'img/notfound.png'} alt={item.nom} className={styles.imgqr}/>
    //           <p className={styles.listtitle}>{item.gameid} - {item.nom}</p>
    //           <p className={styles.listsport}>{item.sport}</p>
    //           <p className={styles.listdesc}>{item.description}</p>
    //         </li>
    //       ))}
    //     </ul>
    //   </div>
    // </div>

    <div className={styles.Game2024}>
    <Title text={"Jeu Sportifs"} />
    <div>
      <ul>
        {allQRFiches.map(item => (
          <li key={item.gameid} className={styles.list}>
            {filteredItems.some(filteredItem => filteredItem.gameid === item.gameid) ? (
              <>
                <img src={item.image || 'img/notfound.png'} alt={item.nom} className={styles.imgqr}/>
                <p className={styles.listtitle}>{item.gameid} - {item.nom}</p>
                <p className={styles.listsport}>{item.nationalite}</p>
                <p className={styles.listsport}>{item.sport}</p>
                <p className={styles.listdesc}>{item.description}</p>
              </>
            ) : (
              <>
                <img src="img/notfound.png" alt="Not Found" className={styles.imgqr}/>
                <p className={styles.listtitle}>{item.gameid} - ???</p>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
    {filteredItems.length} / {allQRFiches.length}
  </div>



  //   <div className={styles.Game2024}>
  //   <Title text={"Jeu Sportifs"} />
  //   <div>
  //     <ul>
  //       {filteredItems.map(item => (
  //         <li key={item.gameid} className={styles.list}>
  //           {idFounds.includes(item.gameid) ? (
  //             <>
  //               <img src={item.image || 'img/notfound.png'} alt={item.nom} className={styles.imgqr}/>
  //               <p className={styles.listtitle}>{item.gameid} - {item.nom}</p>
  //               <p className={styles.listsport}>{item.sport}</p>
  //               <p className={styles.listdesc}>{item.description}</p>
  //             </>
  //           ) : (
  //             <>
  //               <img src="img/notfound.png" alt="Not Found" className={styles.imgqr}/>
  //               <p className={styles.listtitle}>ID: {item.gameid}</p>
  //             </>
  //           )}
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // </div>
  );
}

export default Game2024;
