import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../../components/Title.js/Title";
import styles from "./Game2024LSV.module.scss";
import axios from "axios";
import { getAllFicheQR, getFoundedQR, getAllFicheQRLSV } from "../../../api/game";
import { API_URL } from "../../../conf";

//const API_URL = "http://localhost:3001/";

function Game2024LSV() {

  const [dbUser, setdbUser] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [numberOfFounded, setNumberOfFounder] = useState(0);
  const [allQRLSVFiches, setAllQRLSVFiches] = useState([]);
  const navigate = useNavigate();

  // Effect pour charger dbUser à chaque rendu
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_URL}api/getActualDBuser`);
        //console.log(result);
        if (result.data !== "invalid") {
          setdbUser(result.data);
          //console.log(result.data);
          //console.log(result.data.qrfound_sports_lsv);
        } else {
          console.log("Non connecté");
          navigate('/connection');
        }
      } catch (error) {
        console.log(error);
      }
    };
    //console.log("FETCHING NEW DBUSER")
    fetchData();
  }, [navigate]);

  // Effect pour charger les fiches lorsque dbUser.qrfound_sports_lsv change
  useEffect(() => {
    const fetchFiches = async () => {
      try {
        let fiches = await getAllFicheQRLSV();
        setAllQRLSVFiches(fiches);
        //console.log(fiches);
        //console.log(dbUser);
        let filteredItems = Array.isArray(fiches) ? fiches.filter(item => dbUser.qrfound_sports_lsv.includes(item.gameid)) : [];
        //console.log("result: " + filteredItems);
        setFilteredItems(filteredItems);
      } catch (error) {
        console.log(error);
      }
    };

    fetchFiches();
  }, [dbUser.qrfound_sports_lsv]);

  return (
    <div className={styles.Game2024LSV}>
    <Title text={"Jeu Le-Saviez-Vous"} />
    <div>
      <ul>
        {allQRLSVFiches.map(item => (
          <li key={item.gameid} className={styles.list}>
            {filteredItems.some(filteredItem => filteredItem.gameid === item.gameid) ? (
              <>
                <img src={item.image || 'img/notfound.png'} alt={item.pays} className={styles.imgqr}/>
                <p className={styles.listtitle}>{item.gameid} - {item.pays}</p>
                <p className={styles.listdesc}>{item.description}</p>
              </>
            ) : (
              <>
                <img src="img/notfound.png" alt="Not Found" className={styles.imgqr}/>
                <p className={styles.listtitle}>{item.gameid} - ???</p>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
    {filteredItems.length} / {allQRLSVFiches.length}
  </div>
  );
}

export default Game2024LSV;
