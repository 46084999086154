import { useEffect, useState } from "react";
import styles from "./AdminSideBar.module.scss"
import { Link } from "react-router-dom";

function AdminSideBar(props) {
  const { showFormFiche, toggleFormFiche } = props;
  const { showGestionCompte, toggleGestionCompte } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [showForm, setShowForm] = useState(false); // État pour afficher ou masquer le composant FormFiche


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleForm = () => {
    setShowForm(!showForm); // Inversez l'état lors du clic sur le bouton
    //props.showFormFiche(!showForm);
  };

  // Fonction de gestion de la réactivité
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(false); // Fermer la barre latérale sur un petit écran
    } else {
      setIsOpen(true); // Ouvrir la barre latérale sur un grand écran
    }
  };

  // Écouter les changements de taille d'écran
  useEffect(() => {
    handleResize(); // Appel initial pour définir l'état initial
    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (

    
      <div className={isOpen  ? styles.sidebar.open : styles.sidebar}>
      {/* Contenu de la barre latérale */}
      <button className={styles.hamburger_button} onClick={toggleSidebar}>
          <div className={styles.hamburger_icon}>☰</div>
        </button>
        <div className={styles.sdbr}>

        
        {isOpen ? (
          <li className={styles.side_bar_li}>
            {/* Remplacez l'élément de liste par un bouton */}
            {/* <button onClick={toggleFormFiche}>Créer une fiche</button> */}
            <Link to="/adminpanel">Panel</Link>
          </li>
        ) : ("")}
        {isOpen ? (
          <li className={styles.side_bar_li}>
            {/* Remplacez l'élément de liste par un bouton */}
            {/* <button onClick={toggleFormFiche}>Créer une fiche</button> */}
            <Link to="/adminpanel/fichecreate">Créer une fiche</Link>
          </li>
        ) : ("")}
        {isOpen ? (
          <li className={styles.side_bar_li}>
            {/* Remplacez l'élément de liste par un bouton */}
            {/* <button onClick={toggleGestionCompte}>Gérer les comptes</button> */}
            <Link to="/adminpanel/gestioncomptes">Gérer les comptes</Link>
          </li>
        ) : ("")
        }
        </div>
        
      </div>
  );

}

export default AdminSideBar;