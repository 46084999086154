import { useNavigate } from "react-router-dom";
import Title from "../../../components/Title.js/Title";
import styles from "./GestionComptes.module.scss"
import axios from "axios";
import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/AdminSideBar/AdminSideBar";
import Notification from "../../../components/Notification/Notification";
import { getAllAccounts } from "../../../api/members";

import { API_URL } from "../../../conf";

//const API_URL = "http://localhost:3001/"
//const API_URL = "https://app.pictageek.fr/";


function GestionComptes() {

    axios.defaults.withCredentials = true;
    const navigate = useNavigate()
    useEffect(() => {
        axios.get(`${API_URL}api/CheckIsAdmin`) //vérifie si l'utilisateur a la perm niveau 2.
        .then(result => {
          //console.log(result);
          if(result.data.status !== "Success") {
            navigate('/home')
          } else {
            // if(result.data.permission < 1) { //si non membre ni admin. Donc que utilisateur.
            //     navigate('/home') //on revoir vers la page Home.
            // }
          }
        })
        .catch(err => console.log(err))
    }, [])


    const [notification, setNotification] = useState(null);
    const [successorfail, setSuccessorfail] = useState(true);
    const [notificationVisible, setNotificationVisible] = useState(false);

  const showNotification = (text/*, lnk, lnktxt*/) => {
    setNotification(text);
    //setnotifLink(lnk);
    //setnotifLinktext(lnktxt);
    setNotificationVisible(true);
  };

  const closeNotification = () => {
    //setNotification(null);
    setNotificationVisible(false);
  };



  const [accounts, setAccounts] = useState([]);

//   useEffect(() => {
//     const result = getAllAccounts();
//     console.log(result);
//   });

useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllAccounts(); // Attend la résolution de la Promise
        if (result) {
          setAccounts(result.data);
          //console.log(result.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  const handlePermissionChange = (e, accountId) => {
    const newPermission = parseInt(e.target.value);
  
    axios.put(`${API_URL}api/updatePermission/${accountId}`, {
        permission: newPermission,
      })
      .then((response) => {
        if (response.data.status === 'Success') {
            // Mise à jour réussie, vous pouvez mettre à jour l'état des comptes localement
            const updatedAccounts = accounts.map((account) => {
              if (account._id === accountId) {
                return { ...account, permission: newPermission };
              }
              return account;
            });
            setAccounts(updatedAccounts);
            setSuccessorfail(true);
            showNotification('Permission modifiée avec succès');
          } else {
            setSuccessorfail(false);
            showNotification('Impossible de changer la permission');
          }
        // Mise à jour réussie, vous pouvez mettre à jour l'état des comptes localement
        // pour refléter les nouvelles permissions
      })
      .catch((error) => {
        console.error(error);
        setSuccessorfail(false);
            showNotification('Impossible de changer la permission');
        // Gérez les erreurs en conséquence
      });
  };

  const handleDeleteAccount = (accountId) => {
    //Envoyez une requête au serveur pour supprimer le compte avec l'ID accountId
    axios
      .delete(`${API_URL}api/deleteAccount/${accountId}`)
      .then((response) => {
        if (response.data.status === 'Success') {
          // Suppression réussie, vous pouvez mettre à jour l'état des comptes localement
          const updatedAccounts = accounts.filter((account) => account._id !== accountId);
          setAccounts(updatedAccounts);
          setSuccessorfail(true);
          showNotification('Compte supprimé avec succès');
        } else {
          setSuccessorfail(false);
          showNotification('Impossible de supprimer le compte');
        }
      })
      .catch((error) => {
        setSuccessorfail(false);
          showNotification('Impossible de supprimer le compte');
        console.error(error);
        // Gérez les erreurs en conséquence
      });
  };


  return (
      
        <div className={styles.GestionComptes}>
            <div className={styles.title}>
                <Title text={"Admin Panel"}/>
            </div>
            <div className={styles.sidebar}>
                <AdminSideBar />
            </div>
            <div className={styles.list}>
            <table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Permission</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {accounts.map((account, index) => (
                        <tr key={account._id} className={index % 2 === 0 ? styles.even : styles.odd}>
                            <td>{account.username}</td>
                            <td>
                            <select
                            value={account.permission}
                            onChange={(e) => handlePermissionChange(e, account._id)}
                            className={index % 2 === 0 ? styles.even : styles.odd}
                            >
                            <option value={0}>Utilisateur</option>
                            <option value={1}>Membre</option>
                            <option value={2}>Administrateur</option>
                            </select>
                                {/* {account.permission === 0
                                    ? "Utilisateur"
                                    : account.permission === 1
                                    ? "Membre"
                                    : account.permission === 2
                                    ? "Administrateur"
                                    : account.permission} */}
                            </td>
                            <td>
                            <button onClick={() => handleDeleteAccount(account._id)} className={styles.btn_trash}>
                              <img src="../../../../../img/trashbin.png"></img>
                            </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
                {/* <ul>
                    {
                        
                        
                        accounts.length > 0 ? (
                            

                            accounts.map((account) => (
                                
                                <li key={account._id}>
                                    <strong>Username:</strong> {account.username}, <strong>Permission:</strong> {account.permission == 0 ? "Utilisateur" : account.permission == 1 ? "Membre" : account.permission == 2 ? "Administrateur" : account.permission}
                                </li>
                                ))
                        ) : (
                            
                            <div></div>
                        )

                        
                    }
                </ul> */}
            </div>
            
          
          {notification && (
            <Notification
            message={notification}
            duration={5000} // Durée en millisecondes (5 secondes dans cet exemple)
            onClose={closeNotification}
            visible={notificationVisible}
            success={successorfail}
            // link={notiflink}
            // linktext={notiflinktext}
            />
            )}
        </div>
    );

}

export default GestionComptes;
