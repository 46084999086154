import styles from "./Connection.module.scss"
import Title from "../../components/Title.js/Title";
import { useState } from "react";
import { loginUser } from "../../api/members";
import {Link} from 'react-router-dom';
import axios from "axios";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import Notification from "../../components/Notification/Notification";
import { API_URL } from "../../conf";

//const API_URL = "http://localhost:3001/"
//const API_URL = "https://app.pictageek.fr/";


function Register() {

  const [notification, setNotification] = useState(null);
  const [issuccess, setIsSuccess] = useState(true);
  // const [notiflink, setnotifLink] = useState(null);
  // const [notiflinktext, setnotifLinktext] = useState(null);
  const [notificationVisible, setNotificationVisible] = useState(false);

  const showNotification = (text/*, lnk, lnktxt*/) => {
    setNotification(text);
    //setnotifLink(lnk);
    //setnotifLinktext(lnktxt);
    setNotificationVisible(true);
  };

  const closeNotification = () => {
    //setNotification(null);
    setNotificationVisible(false);
  };

    //c'est email pour la logique mais on demande qu'un mdp
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirm, setPassword_confirm] = useState('');
  const [err_mess, setErrMess] = useState('')

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmChange = (e) => {
    setPassword_confirm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log('Email:', email);
    //console.log('Mot de passe:', password);
    //console.log('Confirm Mot de passe:', password_confirm);

    if(password != password_confirm) {
      var error = "Les deux mots de passe ne correspondent pas.";
      console.log(error);
      setErrMess(error);
    } else {
      setErrMess("");
      axios.post(`${API_URL}api/register`, {username:email, password:password})
      .then(result => {
        //console.log(result);
        if(result.data === "AlreadyExist") {
          setErrMess("Ce nom d'utilisateur est déjà pris.")
        } else {
          setEmail("");
          setPassword("");
          setPassword_confirm("");

          setIsSuccess(true);
          showNotification('Compte crée avec Succès. Vous pouvez vous connecter.');
          

        }
      })
      .catch(err => console.log(err))
    }



    // async function loginfunction(user, pass) {
    //     const mb = await loginUser(user, pass);
    //     if(mb.data.endcode === '-1') {
    //         console.log("Mot de passe ou nom d'utilisateur invalide.")
    //     } else {
    //         //c'est bon
    //     }
    // }

    // loginfunction(email, password);
  };

    return (
        <div className={styles.Register}>
            <div className={styles.container}>
                <Title text={"S'inscrire"}/>
            </div>
            <div className={styles.form}>
                
            <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="email">Nom d'utilisateur</label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
        </div>
        <div className={styles.formGroup}>
            <label htmlFor="password">Confirmer le mot de passe</label>
            <input
              type="password"
              id="password_confirm"
              value={password_confirm}
              onChange={handlePasswordConfirmChange}
              required
            />
        </div>
          
          <button type="submit">S'inscrire</button>
          <div className={styles.errorMessage}>
            <ErrorMessage text={err_mess}/>          
          </div>
          <div className={styles.registerButton}>
            Déjà inscrit ? <Link to="/connection">Connectez-vous</Link>          
          </div>
        </form>
            </div>
            {notification && (
            <Notification
            message={notification}
            duration={5000} // Durée en millisecondes (5 secondes dans cet exemple)
            onClose={closeNotification}
            visible={notificationVisible}
            success={issuccess}
            // link={notiflink}
            // linktext={notiflinktext}
            />
            )}
        </div>
    );

}

export default Register;

/*
{fruits.map((fruit, index) => (
    <CardFruit fruit={fruit} key={index} />
))}
*/