import styles from "./Home.module.scss"
import Title from "../../components/Title.js/Title";
import { useEffect, useState } from "react";
import CardMember from "../../components/CardMember/CardMember";
import Notification from "../../components/Notification/Notification";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from '../../conf'


function Home() {

    const [isConnected, setIsConnected] = useState();

    // const [fruits, setFruits] = useState(['Pomme', 'Poire', 'Abricot', 'Fraise', 'Orange'])

    // const addFruit = (fruit) => {
    //     setFruits([...fruits, fruit]);
    // }

    useEffect(() => {
        axios.get(`${API_URL}api/CheckMembers`) //vérifie si le token est valide donc si l'utilisateur est connecté.
        .then(result => {
          //console.log(result + "FULL DATA ACCESS");
          if(result.data !== "Success") {
            setIsConnected(false);
          } else {
            setIsConnected(true);
          }
        })
        .catch(err => console.log(err))
    }, [])

    //console.log(isConnected)
    return (
        <div className={styles.Home}>
            <div className={styles.container}>
                <Title text={"Page d'accueil"}/>
            </div>

            <div>
            {isConnected ? (
                <p className={styles.home_paragraph}>
                    Bienvenue ! <br />
                    Essayez de trouver tous les QR-Codes cachés !
                </p>                
                ) : (
                <p className={styles.home_paragraph}>
                    Bienvenue ! <br />
                    Pour utiliser la plupart des fonctionnalités, comme les jeux par exemple, vous devez créer un compte. Ça se passe ici :  <Link to="/register" className={styles.home_link}>Créer un compte</Link> 
                </p>
                )
            }
                
            </div>
           
            <div className={styles.gridmember}>
                
                    
            </div>
            
        </div>
    );

}

export default Home;

/*
{fruits.map((fruit, index) => (
    <CardFruit fruit={fruit} key={index} />
))}
*/