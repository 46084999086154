import React, { useState, useEffect } from 'react';
import styles from "./Notification.module.scss"
import { Link } from 'react-router-dom';


function Notification(props) {
//   const [visible, setVisible] = useState(true);
    
//   useEffect(() => {
//     if (props.duration) {
//       const timer = setTimeout(() => {
//         setVisible(false);
//       }, props.duration);
//       return () => {
//         clearTimeout(timer);
//       };
//     }
//   }, [props.duration]);

//   const handleClose = () => {
//     setVisible(false);
//     if (props.onClose) {
//         props.onClose();
//     }
//   };

useEffect(() => {
    if (props.duration && props.visible) {
      const timer = setTimeout(() => {
        props.onClose();
      }, props.duration);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [props.duration, props.onClose, props.visible]);

  const handleClose = () => {
    props.onClose();
  };

  return (
    props.visible && (
      
      <div className={props.success == true ? styles.greennotification : styles.rednotification}>
        <div className={styles.notificationcontent}>
          {props.message} 
          {/* {<Link to={props.link}>{props.linktext}</Link>  } */}
          <button className={styles.closebutton} onClick={handleClose}>
            &#10006;
          </button>
        </div>
      </div>
    )
  );
};

export default Notification;