import { useNavigate } from "react-router-dom";
import Title from "../../components/Title.js/Title";
import styles from "./Panel.module.scss"
import axios from "axios";
import { useEffect, useState } from "react";
import AdminSideBar from "../../components/AdminSideBar/AdminSideBar";

import { API_URL } from "../../conf";
// import Title from "../../components/Title.js/Title";
// import { useState } from "react";
// import CardMember from "../../components/CardMember/CardMember";
// import Notification from "../../components/Notification/Notification";
//const API_URL = "http://localhost:3001/"
//const API_URL = "https://app.pictageek.fr/";


function Panel() {

    const [username, setUsername] = useState('')

    axios.defaults.withCredentials = true;
    const navigate = useNavigate()
    useEffect(() => {
        axios.get(`${API_URL}api/CheckIsAdmin`) //vérifie si l'utilisateur a la perm niveau 2
        .then(result => {
          //console.log(result);
          if(result.data.status !== "Success") {
            navigate('/home')
          } else {
            // if(result.data.permission < 1) { //si non membre ni admin. Donc que utilisateur.
            //     navigate('/home') //on revoir vers la page Home.
            // }
          }
        })
        .catch(err => console.log(err))
    }, [])


    return (
      
        <div className={styles.Panel}>
          <div className={styles.title}>
            <Title text={"Admin Panel"}/>
          </div>
          <div className={styles.sidebar}>
            <AdminSideBar />
          </div>
        </div>
    );

}

export default Panel;
