import React, {useEffect, useState} from "react";
import styles from '../css/App.module.scss';

import {Routes, Route, useNavigate} from 'react-router-dom';
import About from "../Pages/About/About";
import Home from "../Pages/Home/Home";
import Header from "./Header";
import Footer from "./Footer";
import Member from "../Pages/Member/Member";
import Connection from "../Pages/Connection/Connection";
import Register from "../Pages/Connection/Register";
import MyAccount from "../Pages/MyAccount/MyAccount"
import Panel from "../Pages/Panel/Panel";
import FichesCreate from "../Pages/Panel/FichesCreate/FichesCreate";
import axios from "axios";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import GestionComptes from "../Pages/Panel/GestionComptes/GestionComptes";
import Game2024 from "../Pages/JeuQR/2024/Game2024";
import Game2024LSV from "../Pages/JeuQR/2024/Game2024LSV";
import ScanQRSport from "../Pages/JeuQR/2024/scanqrsports";
import ScanQRSportLSV from "../Pages/JeuQR/2024/scanqrsportslsv";

import { API_URL } from "../conf";

function Layout() {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isPermLvl1plus, setIsPermLvl1plus] = useState(false);
    const [isPermLvl2, setIsPermLvl2] = useState(false);


    //const API_URL = "http://localhost:3001/"
    //const API_URL = "https://app.pictageek.fr/";

    axios.defaults.withCredentials = true;
    let navigate = useNavigate();

    useEffect(() => {
        axios.get(`${API_URL}api/CheckMembers`)
        .then(result => {
          //console.log(result);
          if(result.data !== "Success") {
            setIsAuthenticated(false);
            navigate('/connection')
          } else {
            setIsAuthenticated(true);
          }
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        axios.get(`${API_URL}api/CheckIsMember`) //vérifie si l'utilisateur a la perm niveau 1.
        .then(result => {
          //console.log(result);
          if(result.data.status !== "Success") {
            setIsPermLvl1plus(false);
            //navigate('/home')
          } else {
            setIsPermLvl1plus(true);
            // if(result.data.permission < 1) { //si non membre ni admin. Donc que utilisateur.
            //     navigate('/home') //on revoir vers la page Home.
            // }
          }
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
      axios.get(`${API_URL}api/CheckIsAdmin`) //vérifie si l'utilisateur a la perm niveau 2.
      .then(result => {
        //console.log(result);
        if(result.data.status !== "Success") {
          setIsPermLvl2(false);
          //navigate('/home')
        } else {
          setIsPermLvl2(true);
          // if(result.data.permission < 1) { //si non membre ni admin. Donc que utilisateur.
          //     navigate('/home') //on revoir vers la page Home.
          // }
        }
      })
      .catch(err => console.log(err))
  }, [])

    return (
        <div className={styles.Layout}>
            <Header isAuthenticated={isAuthenticated} isPermLvl1plus={isPermLvl1plus} isPermLvl2={isPermLvl2} />
            
            <Routes>
              <Route path="adminpanel" element={<Panel />} />
              <Route path="adminpanel/fichecreate" element={<FichesCreate />} />
              <Route path="adminpanel/gestioncomptes" element={<GestionComptes />} />
            </Routes>
        <div className={styles.LayoutBody}>
          
            <div className={styles.container}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="home" element={<Home />} />
                    <Route path="game" element={<Game2024 />} /> {/* Jeu QR Sportifs*/}
                    <Route path="scanqrsport/:urlid" element={<ScanQRSport />} /> 
                    <Route path="scanqrsportlsv/:urlid" element={<ScanQRSportLSV />} /> 
                    <Route path="gamelsv" element={<Game2024LSV />} /> {/*Jeu QR Le Saviez-Vous */}

                    
                    <Route path="member" element={<Member />} />
                    <Route path="member/:memberid" element={<Member />} />
                    
                    {/* <Route path="member" element={<ProtectedRoute direction={<Member />} />} /> */}

                    {/* <Route path="about" element={<About />} /> */}
                    <Route path="connection" element={<Connection />} />
                    <Route path="register" element={<Register />} />
                    <Route path="myaccount" element={<MyAccount />} />
                </Routes>
            </div>
        </div>
          
            

            <Footer />
        </div>
    );

}

export default Layout;