import styles from "./Connection.module.scss"
import Title from "../../components/Title.js/Title";
import { useState } from "react";
import { loginUser } from "../../api/members";
import {Link} from 'react-router-dom';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

import { API_URL } from "../../conf";

//const API_URL = "http://localhost:3001/"


function Connection() {


  let navigate = useNavigate();


    //c'est email pour la logique mais on demande qu'un mdp
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err_mess, setErrMess] = useState('')

  // const [credentials, setCredentials] = useState({
  //   login: '',
  //   password: ''
  // });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    
    // setCredentials({
    //   "login": e.target.value,
    //   "password": credentials.password
    // });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);

    // setCredentials({
    //   "login": credentials.login,
    //   "password": e.target.value
    // });
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setCredentials({
  //     "login": value
  //   });
  // };

  axios.defaults.withCredentials = true;

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log({username:email, password:password});
    axios.post(`${API_URL}api/login`, {username:email, password:password})
      .then(res => {
        //console.log(res.data)
        if(res.data.status === "Success") {
          navigate('/home')
          navigate(0);
          //window.location.reload();
        } else {
          console.log("Mot de passe ou nom d'utilisateur incorrect.");
          setErrMess('Nom d\'utilisateur ou mot de passe incorrect.')
        }
      })
      .catch(error => {console.log(error)})

    // e.preventDefault();
    // console.log('Email:', credentials.login);
    // console.log('Mot de passe:', credentials.password);

    // async function loginfunction(user, pass) {
    //     const mb = await loginUser(user, pass);
    //     if(mb.data.endcode === '-1') {
    //         console.log("Mot de passe ou nom d'utilisateur invalide.")
    //     } else {
    //         //c'est bon

    //     }
    // }

    // loginfunction(credentials.login, credentials.password);
  };

    return (
        <div className={styles.Connection}>
            <div className={styles.container}>
                <Title text={"Connexion"}/>
            </div>
            <div className={styles.form}>
                
            <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="email">Nom d'utilisateur</label>
            <input
              type="text"
              id="login"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <button type="submit">Se connecter</button>
          <div className={styles.errorMessage}>
            <ErrorMessage text={err_mess}/>          
          </div>
          <div className={styles.registerButton}>
            <Link to="/register">Créer un compte</Link>          
          </div>
        </form>
            </div>
        </div>
    );

}

export default Connection;

/*
{fruits.map((fruit, index) => (
    <CardFruit fruit={fruit} key={index} />
))}
*/