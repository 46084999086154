import Title from "../../components/Title.js/Title";
import styles from "./About.module.scss"


function About() {

    return (
        <div className={styles.About}>
            <div className={styles.container}>
                <Title text="À propos" />
            </div>
        </div>
    );

}

export default About;