import styles from '../css/App.module.scss';

function Footer() {
    return (
        <footer className={styles.mainfooter}>
            <div className={styles.footercolumn}>
                © 2024 Pictageeks
            </div>
        </footer>
    );

}

export default Footer;