import styles from '../css/App.module.scss';
import {Link} from 'react-router-dom';

function Header(props) {
    return (
        <header className={styles.mainheader}>
            <div className={styles.logo}> 
            <Link to="/"><img src="../img/LOGO-Blanc-Transparent.png" alt="Home"></img></Link>
            </div>
            <nav className={styles.navbar}>
                <ul className={styles.list}>
                    <li className={styles.link}><Link to="/">Home</Link></li>
                    
                    {props.isAuthenticated ? (
                        <li className={styles.link}><Link to="game">Jeu Sportifs</Link></li>
                        ) : (
                        <li className={styles.link}></li>
                        )
                    }

                    {props.isAuthenticated ? (
                        <li className={styles.link}><Link to="gamelsv">Jeu Le Saviez-Vous</Link></li>
                        ) : (
                        <li className={styles.link}></li>
                        )
                    }

                    {props.isAuthenticated && props.isPermLvl1plus ? (
                        <li className={styles.link}><Link to="member">Fiches</Link></li>
                        ) : (
                        // <li className={styles.link}><Link to="connection">Se Connecter</Link></li>
                        <li className={styles.link}></li>
                        )
                    }
                    {props.isAuthenticated && props.isPermLvl2 ? (
                        <li className={styles.link}><Link to="adminpanel">Panel</Link></li>
                        ) : (
                        // <li className={styles.link}><Link to="connection">Se Connecter</Link></li>
                        <li className={styles.link}></li>
                        )
                    }
                    
                    
                    {props.isAuthenticated ? (
                        <li className={styles.link}><Link to="myaccount">Mon Compte</Link></li>
                        ) : (
                        <li className={styles.link}><Link to="connection">Se Connecter</Link></li>
                        )
                    }
                    
                </ul>
            </nav>
            
            
        </header>
    );

}

export default Header;