import React, {useEffect, useState} from "react";
import styles from './css/App.module.scss';
import Layout from './Layout/Layout';


function App() {

    return (
        <div className={styles.App}>
            
            <Layout />
            
        </div>
    );

}

export default App;