import { useEffect, useState } from "react";
import { getAllCardsMember, getAllRelevantMembers, getMembersById } from "../../api/members";
import styles from "./Member.module.scss"
import CardMember from "../../components/CardMember/CardMember";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Notification from "../../components/Notification/Notification";
import { API_URL } from "../../conf";

function Member() {

    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const [issuccess, setIsSuccess] = useState(true);
    const [notification, setNotification] = useState(null);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const showNotification = (text/*, lnk, lnktxt*/) => {
        setNotification(text);
        //setnotifLink(lnk);
        //setnotifLinktext(lnktxt);
        setNotificationVisible(true);
      };
    
      const closeNotification = () => {
        //setNotification(null);
        setNotificationVisible(false);
      };

    const [allCards, setAllCards] = useState([]);

    //const API_URL = "http://localhost:3001/"
    //const API_URL = "https://app.pictageek.fr/";

    axios.defaults.withCredentials = true;
    let navigate = useNavigate();

    useEffect(() => {
        axios.get(`${API_URL}api/CheckMembers`) //vérifie si le token est valide donc si l'utilisateur est connecté.
        .then(result => {
          //console.log(result);
          if(result.data !== "Success") {
            navigate('/connection')
          }
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        axios.get(`${API_URL}api/CheckIsMember`) //vérifie si l'utilisateur a la perm niveau 1.
        .then(result => {
          //console.log(result);
          if(result.data.status !== "Success") {
            navigate('/home')
          } else {
            setIsAdmin(false);
            // if(result.data.permission < 1) { //si non membre ni admin. Donc que utilisateur.
            //     navigate('/home') //on revoir vers la page Home.
            // }
          }
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        axios.get(`${API_URL}api/CheckIsAdmin`) //vérifie si l'utilisateur a la perm niveau 1.
        .then(result => {
          //console.log(result);
          if(result.data.status !== "Success") {
           // navigate('/home')
          } else {
            setIsAdmin(true);
            // if(result.data.permission < 1) { //si non membre ni admin. Donc que utilisateur.
            //     navigate('/home') //on revoir vers la page Home.
            // }
          }
        })
        .catch(err => console.log(err))
    }, [])

    

    useEffect(() => {
        // Effectuez la requête pour récupérer tous les documents
        getAllCardsMember() // Remplacez par votre fonction de requête MongoDB
          .then((response) => {
            // Mettez à jour l'état avec les documents récupérés
            setAllCards(response.data.data);
            //console.log(response.data.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, []);

    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
    const [member, setMember] = useState({});
    const [searchcode, setSearchCode] = useState('');
    const { memberid } = useParams();
    
    useEffect(() => {
        //console.log("memberid: ", memberid);
        async function getMemberLoadById(memberid) {
            const mb = await getMembersById(memberid, token);
            //console.log("membername: ", mb.data.nom);
            if(mb.data.code === '-1') {
                setMember({nom: "Identifiant Invalide"});
                

            } else {
                setMember(mb.data);
                setSearchResults([mb.data])
            }
        }
        getMemberLoadById(memberid);

    }, [memberid]);

    const handleClick = async (e) => {
       
        setSearchValue(e.target.value)

        e.preventDefault();
        return; //PLUS UTILE
        if (searchValue === '' || searchValue === undefined) {
            console.error("PAS ID");
        } else {
            //console.log(searchValue);
            // Effectuez la recherche et stockez les résultats dans setSearchResults
            // Exemple :
            // const results = await searchMembers(searchValue);
            // setSearchResults(results);

            async function getMemberLoadById(searchcode) {
                const mb = await getMembersById(searchcode, token);
                if(mb.data.code === '-1') {
                    setMember({nom: "Identifiant Invalide"});
    
                } else {
                    setMember(mb.data);
                    //setSearchResults(mb.data);
                }
            }
    
            getMemberLoadById(searchcode);

            
        }

        e.preventDefault();
        if(searchcode === '' || searchcode === undefined) {
            console.error("PAS ID")
        } else {
            //console.log(searchcode);
    
    
            async function getMemberLoadById(searchcode) {
                const mb = await getMembersById(searchcode, token);
                if(mb.data.code === '-1') {
                    setMember({nom: "Identifiant Invalide"});
    
                } else {
                    setMember(mb.data);
                }
            }
    
            getMemberLoadById(searchcode);
        }
        
    }

    const handleChange = async (e) => {
        //setSearchCode(e.target.value);
        const newValue = e.target.value;
        setSearchValue(newValue);
        // Effectuez la recherche en temps réel ici et mettez à jour setSearchResults
        // Exemple :
        async function getByInfo(searchcode) {
            //const mb = await getAllRelevantMembers(searchcode);
            
            const documents = await getAllRelevantMembers(searchcode);
            //console.log(documents.data.data);

            if(documents.data.data) {
                setSearchResults(documents.data.data);
                //console.log(documents.data.data[0].nom);
                //const mb = documents.data[0];
            } else {
                setSearchResults([]);

                //setSearchResults(documents.data.data);
            }

            /*if(mb.data.code === '-1') {
                //setMember({nom: "Identifiant Invalide"});

            } else {
                //setMember(mb.data);
                //console.log(mb.regime_alimentaire);
                //setSearchResults(mb.data);
            }*/
        }

        getByInfo(newValue);

        
      }
    
      

    // async function getAllCards() {
    //     const documents = await getAllCardsMember();
    //     console.log(documents.data.data);

    //     if(documents.data.data) {
    //         return documents.data.data;
    //     }
    // }

    
    // useEffect(() => {
    //     setAllCards(getAllCards());
    // }, [])

    const handleDeleteFiche = (id) => {
            
    //Envoyez une requête au serveur pour supprimer le compte avec l'ID accountId
    axios
    .delete(`${API_URL}api/deleteFiche/${id}`)
    .then((response) => {
      if (response.data.status === 'Success') {
        // Suppression réussie, vous pouvez mettre à jour l'état des comptes localement
        //const updatedAccounts = accounts.filter((account) => account._id !== id);
        //setAccounts(updatedAccounts);
        setIsSuccess(true);
        showNotification('Fiche supprimée avec succès');

        async function getByInfo(searchcode) {            
            //const mb = await getAllRelevantMembers(searchcode);
            
            const documents = await getAllRelevantMembers(searchcode);
            //console.log(documents.data.data);

            if(documents.data.data) {
                setSearchResults(documents.data.data);
                //console.log(documents.data.data[0].nom);
                //const mb = documents.data[0];
            } else {
                setSearchResults([]);

                //setSearchResults(documents.data.data);
            }

            /*if(mb.data.code === '-1') {
                //setMember({nom: "Identifiant Invalide"});

            } else {
                //setMember(mb.data);
                //console.log(mb.regime_alimentaire);
                //setSearchResults(mb.data);
            }*/
        }

            getByInfo(searchValue);
            getAllCardsMember() // Remplacez par votre fonction de requête MongoDB
            .then((response) => {
              // Mettez à jour l'état avec les documents récupérés
              //setSearchResults([]);
              setAllCards(response.data.data);
              //console.log(response.data.data);
            })
            .catch((error) => {
              console.error(error);
            });

      } else {
        setIsSuccess(false);
        showNotification('Impossible de supprimer la fiche');
      }
    })
    .catch((error) => {
      console.error(error);
      setIsSuccess(false);
        showNotification('Impossible de supprimer la fiche');
      // Gérez les erreurs en conséquence
    });

    }


    const handlePointage = (id, type) => {

      axios
      .put(`${API_URL}api/pointageFiche/${id}`, {id: id, type:type})
      .then((response) => {
        if (response.data.status === 'Success') {
          setIsSuccess(true);
          showNotification('Pointage effectué');
  
          async function getByInfo(searchcode) {            
              const documents = await getAllRelevantMembers(searchcode);
              if(documents.data.data) {
                  setSearchResults(documents.data.data);
              } else {
                  setSearchResults([]);
              }
          }
              getByInfo(searchValue);
              getAllCardsMember() 
              .then((response) => {
                setAllCards(response.data.data);
              })
              .catch((error) => {
                console.error(error);
              });
  
        } else {
          setIsSuccess(false);
          showNotification('Impossible de Pointer');
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSuccess(false);
          showNotification('Impossible de Pointer');
        // Gérez les erreurs en conséquence
      });
    }

    const handleCancelPointage = (id, type) => {

      axios
      .put(`${API_URL}api/cancelPointageFiche/${id}`, {id: id, type:type})
      .then((response) => {
        if (response.data.status === 'Success') {
          setIsSuccess(true);
          showNotification('Annulation pointage effectué');
  
          async function getByInfo(searchcode) {            
              const documents = await getAllRelevantMembers(searchcode);
              if(documents.data.data) {
                  setSearchResults(documents.data.data);
              } else {
                  setSearchResults([]);
              }
          }
              getByInfo(searchValue);
              getAllCardsMember() 
              .then((response) => {
                setAllCards(response.data.data);
              })
              .catch((error) => {
                console.error(error);
              });
  
        } else {
          setIsSuccess(false);
          showNotification('Impossible d\'annuler le pointage');
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSuccess(false);
        showNotification('Impossible d\'annuler le pointage');
        // Gérez les erreurs en conséquence
      });
    }



    
    const handlePointageRepasFiche = (id) => {
            
        //Envoyez une requête au serveur pour supprimer le compte avec l'ID accountId
        axios
        .put(`${API_URL}api/pointageRepasFiche/${id}`)
        .then((response) => {
          if (response.data.status === 'Success') {
            // Suppression réussie, vous pouvez mettre à jour l'état des comptes localement
            //const updatedAccounts = accounts.filter((account) => account._id !== id);
            //setAccounts(updatedAccounts);
            setIsSuccess(true);
            showNotification('Pointage effectué');
    
            async function getByInfo(searchcode) {            
                //const mb = await getAllRelevantMembers(searchcode);
                
                const documents = await getAllRelevantMembers(searchcode);
                //console.log(documents.data.data);
    
                if(documents.data.data) {
                    setSearchResults(documents.data.data);
                    //console.log(documents.data.data[0].nom);
                    //const mb = documents.data[0];
                } else {
                    setSearchResults([]);
    
                    //setSearchResults(documents.data.data);
                }
    
                /*if(mb.data.code === '-1') {
                    //setMember({nom: "Identifiant Invalide"});
    
                } else {
                    //setMember(mb.data);
                    //console.log(mb.regime_alimentaire);
                    //setSearchResults(mb.data);
                }*/
            }
    
                getByInfo(searchValue);
                getAllCardsMember() // Remplacez par votre fonction de requête MongoDB
                .then((response) => {
                  // Mettez à jour l'état avec les documents récupérés
                  //setSearchResults([]);
                  setAllCards(response.data.data);
                  //console.log(response.data.data);
                })
                .catch((error) => {
                  console.error(error);
                });
    
          } else {
            setIsSuccess(false);
            showNotification('Impossible de Pointer pour le repas');
          }
        })
        .catch((error) => {
          console.error(error);
          setIsSuccess(false);
            showNotification('Impossible de Pointer pour le repas');
          // Gérez les erreurs en conséquence
        });
    
        }

        const handlePointageEnterFiche = (id) => {
            
          //Envoyez une requête au serveur pour supprimer le compte avec l'ID accountId
          axios
          .put(`${API_URL}api/pointageEnterFiche/${id}`)
          .then((response) => {
            if (response.data.status === 'Success') {
              // Suppression réussie, vous pouvez mettre à jour l'état des comptes localement
              //const updatedAccounts = accounts.filter((account) => account._id !== id);
              //setAccounts(updatedAccounts);
              setIsSuccess(true);
              showNotification('Pointage effectué');
      
              async function getByInfo(searchcode) {            
                  //const mb = await getAllRelevantMembers(searchcode);
                  
                  const documents = await getAllRelevantMembers(searchcode);
                  //console.log(documents.data.data);
      
                  if(documents.data.data) {
                      setSearchResults(documents.data.data);
                      //console.log(documents.data.data[0].nom);
                      //const mb = documents.data[0];
                  } else {
                      setSearchResults([]);
      
                      //setSearchResults(documents.data.data);
                  }
      
                  /*if(mb.data.code === '-1') {
                      //setMember({nom: "Identifiant Invalide"});
      
                  } else {
                      //setMember(mb.data);
                      //console.log(mb.regime_alimentaire);
                      //setSearchResults(mb.data);
                  }*/
              }
      
                  getByInfo(searchValue);
                  getAllCardsMember() // Remplacez par votre fonction de requête MongoDB
                  .then((response) => {
                    // Mettez à jour l'état avec les documents récupérés
                    //setSearchResults([]);
                    setAllCards(response.data.data);
                    //console.log(response.data.data);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
      
            } else {
              setIsSuccess(false);
              showNotification('Impossible de Pointer pour l\'arrivé');
            }
          })
          .catch((error) => {
            console.error(error);
            setIsSuccess(false);
              showNotification('Impossible de Pointer pour l\'arrivé');
            // Gérez les erreurs en conséquence
          });
      
          }


          // JOUR 2 :

          
    const handlePointageRepasFicheJour2 = (id) => {
            
      //Envoyez une requête au serveur pour supprimer le compte avec l'ID accountId
      axios
      .put(`${API_URL}api/pointageRepasFicheJour2/${id}`)
      .then((response) => {
        if (response.data.status === 'Success') {
          // Suppression réussie, vous pouvez mettre à jour l'état des comptes localement
          //const updatedAccounts = accounts.filter((account) => account._id !== id);
          //setAccounts(updatedAccounts);
          setIsSuccess(true);
          showNotification('Pointage effectué');
  
          async function getByInfo(searchcode) {                          
              const documents = await getAllRelevantMembers(searchcode);
              if(documents.data.data) {
                  setSearchResults(documents.data.data);
              } else {
                  setSearchResults([]);
              }
          }
              getByInfo(searchValue);
              getAllCardsMember()
              .then((response) => {
                setAllCards(response.data.data);
              })
              .catch((error) => {
                console.error(error);
              });
  
        } else {
          setIsSuccess(false);
          showNotification('Impossible de Pointer pour le repas');
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSuccess(false);
          showNotification('Impossible de Pointer pour le repas');
        // Gérez les erreurs en conséquence
      });
  
      }

      const handlePointageEnterFicheJour2 = (id) => {
          
        //Envoyez une requête au serveur pour supprimer le compte avec l'ID accountId
        axios
        .put(`${API_URL}api/pointageEnterFicheJour2/${id}`)
        .then((response) => {
          if (response.data.status === 'Success') {
            setIsSuccess(true);
            showNotification('Pointage effectué');

            async function getByInfo(searchcode) {            
                const documents = await getAllRelevantMembers(searchcode);    
                if(documents.data.data) {
                    setSearchResults(documents.data.data);
                } else {
                    setSearchResults([]);
                }
            }
    
                getByInfo(searchValue);
                getAllCardsMember() 
                .then((response) => {
                  setAllCards(response.data.data);
                })
                .catch((error) => {
                  console.error(error);
                });
    
          } else {
            setIsSuccess(false);
            showNotification('Impossible de Pointer pour l\'arrivé');
          }
        })
        .catch((error) => {
          console.error(error);
          setIsSuccess(false);
            showNotification('Impossible de Pointer pour l\'arrivé');
          // Gérez les erreurs en conséquence
        });
      }
      
    return (
        <div className={styles.main}>
            <div className={styles.searchBar}>
                <form onSubmit={handleClick}>
                    <label htmlFor="code"></label>

                    <div className={styles.search_text}>
                        Rechercher un membre, un exposant, une information...<br />
                        Recherchez un ou plusieurs mots clefs exact en les mettant entre guillemets.
                    </div>
                    
                    <div className={styles.search_wraper}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                        <input className={styles.search_input} type="text" id="code" name="code" onChange={handleChange}/>
                    </div>
                    <button className={styles.search_button}>
                        
                    </button>
                </form>
            </div>
            <div className={styles.cardcontainer}>
            {
                searchResults.length > 0 ? (
                searchResults.map((result, index) => (
                  <CardMember data={result} id={result.code} handleDelete={handleDeleteFiche} handlePointage={handlePointage} handleCancelPointage={handleCancelPointage} isAdmin={isAdmin}/>

                    //<CardMember data={result} id={result.code} handleDelete={handleDeleteFiche} handlePointageRepas={handlePointageRepasFiche} handlePointageEnter={handlePointageEnterFiche} handlePointageRepasJour2={handlePointageRepasFicheJour2} handlePointageEnterJour2={handlePointageEnterFicheJour2} handlePointageSortie={handlePointageSortieFiche} handlePointageSortieJour2={handlePointageSortieFicheJour2}  isAdmin={isAdmin}/>
                ))
                ) : (
                    //ALL
                    searchValue == "" ? (
                        allCards.map((result, index) => (
                          
                          <CardMember data={result} id={result.code} handleDelete={handleDeleteFiche} handlePointage={handlePointage} handleCancelPointage={handleCancelPointage} isAdmin={isAdmin}/>
                            //<CardMember data={result} id={result.code} handleDelete={handleDeleteFiche} handlePointageRepas={handlePointageRepasFiche} handlePointageEnter={handlePointageEnterFiche} handlePointageRepasJour2={handlePointageRepasFicheJour2} handlePointageEnterJour2={handlePointageEnterFicheJour2} handlePointageSortie={handlePointageSortieFiche} handlePointageSortieJour2={handlePointageSortieFicheJour2} isAdmin={isAdmin}/>
                        ))
                    ) : (
                        <div></div>
                    )
                //<CardMember data={member} id={memberid} />
                )
            }

            {/* <CardMember data={member} id={memberid} /> */}
            </div>
            {notification && (
            <Notification
            message={notification}
            duration={5000} // Durée en millisecondes (5 secondes dans cet exemple)
            onClose={closeNotification}
            visible={notificationVisible}
            success={issuccess}
            // link={notiflink}
            // linktext={notiflinktext}
            />
            )}
        </div>
    );

}

export default Member;