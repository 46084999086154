import styles from "./CardMember.module.scss"

function formatePhone(telephone) {
    // Supprimer les espaces existants s'ils existent
    const numeroSansEspaces = telephone.replace(/\s/g, '');
  
    // Vérifier si le numéro a déjà des espaces (groupes de 2 caractères)
    if (/^\d{2}(\s\d{2})*$/.test(numeroSansEspaces)) {
      // Le numéro est déjà formaté, donc le retourner tel quel
      return numeroSansEspaces;
    } else {
      // Le numéro n'est pas formaté, ajouter des espaces entre chaque paire de caractères
      return numeroSansEspaces.match(/\d{1,2}/g).join(' ');
    }
  }

  

//function CardMember({ data, id, handleDelete, handlePointageRepas, handlePointageEnter, handlePointageRepasJour2, isAdmin}) {
function CardMember({ data, id, handleDelete, handlePointage, handleCancelPointage, isAdmin}) {
    
    
    

    if(data.type == "Exposant") {
        data.nom = data.nom_stand;
    }

    if(data.nom === "Identifiant Invalide" || data.nom === undefined) {
        return (
            <div className={styles.main}>
                <h1 className={styles.title}>{data.nom}</h1>
            </div>
        );
    } else {
        const date = new Date(data.naissance);

        // Extraire le jour, le mois et l'année
        const jour = date.getDate();
        const mois = date.getMonth() + 1; // Les mois sont basés sur 0, alors ajoutez 1.
        const annee = date.getFullYear();

        // Formater la date en "JJ/MM/AAAA"
        const dateFormatee = `${jour.toString().padStart(2, '0')}/${mois.toString().padStart(2, '0')}/${annee}`;

        //console.log(data.type);

        return (
            <div className={styles.main}>
                <div className={styles.containerTrash}>
                {isAdmin == true ? 
                (
                    <button className={styles.trashButton} onClick={() => handleDelete(data._id)}>
                        <img src="/img/trashbin_black.png" alt="Supprimer" title="Supprimer la fiche"/>
                    </button>
                    ) : (<div></div>)
                }
                </div>





                {data.type === "Staff" && isAdmin ? (
  <>
    {/* Pointage Jour 1 */}
    <div className={styles.buttonscontainer}>
      {/* Bouton Pointage arrivée jour 1 */}
      {data.pointageenterj1 == false ? (
        <>
          <button className={styles.PointageEnterButton} onClick={() => handlePointage(data.code, "enterj1")}>
            <img src="/img/enter_j1.png" alt="Pointer arrivé jour 1" title="Pointer pour l'arrivé jour 1"/>
          </button>
        </>
      ) : (
        <button className={styles.PointageEnterButton} onClick={() => handleCancelPointage(data.code, "enterj1")}>
          <img src="/img/cancel_enter_j1.png" alt="Annuler pointage arrivé jour 1" title="Annuler pointage pour l'arrivé jour 1"/>
        </button>
      )}

      {/* Bouton Pointage repas jour 1 */}
      {data.pointagemealj1 == false ? (
        <>
          <button className={styles.PointageButton} onClick={() => handlePointage(data.code, "repasj1")}>
            <img src="/img/eat_j1.png" alt="Pointer repas jour 1" title="Pointer pour le repas du jour 1"/>
          </button>
        </>
      ) : (
        <button className={styles.PointageButton} onClick={() => handleCancelPointage(data.code, "repasj1")}>
          <img src="/img/cancel_eat_j1.png" alt="Annuler pointage repas jour 1" title="Annuler pointage pour le repas du jour 1"/>
        </button>
      )}

      {/* Bouton Pointage sortie jour 1 */}
      {data.pointagesortiej1 == false ? (
        <>
          <button className={styles.PointageEnterButton} onClick={() => handlePointage(data.code, "sortiej1")}>
            <img src="/img/sortie_j1.png" alt="Pointer sortie jour 1" title="Pointer pour la sortie jour 1"/>
          </button>
        </>
      ) : (
        <button className={styles.PointageEnterButton} onClick={() => handleCancelPointage(data.code, "sortiej1")}>
          <img src="/img/cancel_sortie_j1.png" alt="Annuler pointage sortie jour 1" title="Annuler pointage pour la sortie jour 1"/>
        </button>
      )}
    </div>

                <div className={styles.buttonscontainer}>
    
    {/* Pointage Jour 2 */}
    
      {/* Bouton Pointage arrivée jour 2 */}
      {data.pointageenterj2 == false ? (
        <>
          <button className={styles.PointageEnterButton} onClick={() => handlePointage(data.code, "enterj2")}>
            <img src="/img/enter_j2.png" alt="Pointer arrivé jour 2" title="Pointer pour l'arrivé jour 2"/>
          </button>
        </>
      ) : (
        <button className={styles.PointageEnterButton} onClick={() => handleCancelPointage(data.code, "enterj2")}>
          <img src="/img/cancel_enter_j2.png" alt="Annuler pointage arrivé jour 2" title="Annuler pointage pour l'arrivé jour 2"/>
        </button>
      )}

      {/* Bouton Pointage repas jour 2 */}
      {data.pointagemealj2 == false ? (
        <>
          <button className={styles.PointageButton} onClick={() => handlePointage(data.code, "repasj2")}>
            <img src="/img/eat_j2.png" alt="Pointer repas jour 2" title="Pointer pour le repas du jour 2"/>
          </button>
        </>
      ) : (
        <button className={styles.PointageButton} onClick={() => handleCancelPointage(data.code, "repasj2")}>
          <img src="/img/cancel_eat_j2.png" alt="Annuler pointage repas jour 2" title="Annuler pointage pour le repas du jour 2"/>
        </button>
      )}

      {/* Bouton Pointage sortie jour 2 */}
      {data.pointagesortiej2 == false ? (
        <>
          <button className={styles.PointageEnterButton} onClick={() => handlePointage(data.code, "sortiej2")}>
            <img src="/img/sortie_j2.png" alt="Pointer sortie jour 2" title="Pointer pour la sortie jour 2"/>
          </button>
        </>
      ) : (
        <button className={styles.PointageEnterButton} onClick={() => handleCancelPointage(data.code, "sortiej2")}>
          <img src="/img/cancel_sortie_j2.png" alt="Annuler pointage sortie jour 2" title="Annuler pointage pour la sortie jour 2"/>
        </button>
      )}
      </div>
  </>
) : (<></>)}
                

                
                    
                <h1 className={styles.title}>{data.nom} {data.prenom}</h1>
                <div className={styles.containers}>
                    <div className={styles.containerMissions}>
                    
                        {/* <div className={styles.subtitle}>
                            <h5>Infos</h5>
                        </div> */}


                {data.type === "Staff" && (
                    <>
                        <ul className={styles.missions}>
                            <li key="0"><span className={styles.bold}>{data.type}</span></li>
                            <li key="1">Date de naissance: <span className={styles.bold}>{dateFormatee}</span></li>
                            <li key="2">Téléphone: <span className={styles.bold}>{formatePhone(data.telephone)}</span></li>
                            <li key="3">Mail: <span className={styles.bold}>{data.mail}</span></li>
                            <li key="4">Contact de secours: <span className={styles.bold}>{data.contact_secours}</span></li>
                            <li key="5">Pôle: <span className={styles.bold}>{data.pole}</span></li>
                            {/* <li key="4">Planning: <span className={styles.bold}>{data.planning}</span></li> */}
                            <li key="6">Taille de vêtement: <span className={styles.bold}>{data.taille_vetement.toUpperCase()}</span></li>
                            <li key="7">Régime alimentaire: <span className={styles.bold}>{data.regime_alimentaire}</span></li>
                            <li key="8">Pointage arrivés: <span className={styles.bold}>{data.pointageenterj1 == true ? `Jour 1 : ${data.pointageDate[2]} ` : `Jour 1 : Non-pointé `} <br /> {data.pointageenterj2 == true ? `Jour 2 : ${data.pointageDate[3]} ` : `Jour 2 : Non-pointé `}</span></li>
                            <li key="9">Pointage repas: <span className={styles.bold}>{data.pointagemealj1 == true ? `Jour 1 : ${data.pointageDate[0]} ` : `Jour 1 : Non-pointé `} <br /> {data.pointagemealj2 == true ? `Jour 2 : ${data.pointageDate[1]} ` : `Jour 2 : Non-pointé `}</span></li>
                            <li key="10">Pointage sortie: <span className={styles.bold}>{data.pointagesortiej1 == true ? `Jour 1 : ${data.pointageDate[4]} ` : `Jour 1 : Non-pointé `} <br /> {data.pointagesortiej2 == true ? `Jour 2 : ${data.pointageDate[5]} ` : `Jour 2 : Non-pointé `}</span></li>
                            <li key="11">Identifiant: <span className={styles.bold}>{data.code}</span></li>
                        </ul>
                    </>
                    
                )}
          {data.type === "Exposant" && (
            <>
              <ul className={styles.missions}>
                            <li key="110"><span className={styles.bold}>{data.type}</span></li>
                            <li key="112">Numéro de stand: <span className={styles.bold}>{data.numero_stand}</span></li>
                            <li key="113">Nom du responsable: <span className={styles.bold}>{data.nom_responsable}</span></li>
                            <li key="114">Prénom du responsable: <span className={styles.bold}>{data.prenom_responsable}</span></li>
                            <li key="115">telephone: <span className={styles.bold}>{formatePhone(data.telephone)}</span></li>
                            <li key="116">Taille du stand: <span className={styles.bold}>{data.taille_stand}</span></li>
                            <li key="117">Type de stand: <span className={styles.bold}>{data.type_stand}</span></li>
                            <li key="118">Nombre de pass Exposant: <span className={styles.bold}>{data.nombre_pass_exposant}</span></li>
                            <li key="119">Identifiant: <span className={styles.bold}>{data.code}</span></li>
                        </ul>
            </>
            
          )}
                        
                        {/* {data.map((element, index) => (
                            <li key={index}>{element}</li>
                        ))} */}

                            
                        
                        
                    </div>
                </div>
                
            </div>
        );
    }
}

export default CardMember;

/*
<div className={styles.main}>
    <h2 className={styles.title}>{fruit}</h2>
</div>
*/