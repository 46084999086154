import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import Title from "../../../components/Title.js/Title";
import styles from "./scanqrsports.module.scss";
import { API_URL } from "../../../conf";
import { getAllFicheQR, getFoundedQR } from "../../../api/game";

function ScanQRSport() {
  const { urlid } = useParams();
  const navigate = useNavigate();
  const [dbUser, setdbUser] = useState(null);
  const [allQRFiches, setAllQRFiches] = useState([]);
  const [foundQR, setFoundQR] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${API_URL}api/getActualDBuser`);
        if (response.data !== "invalid") {
          setdbUser(response.data);
        } else {
          console.log("Non connecté");
          navigate('/connection');
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchAllQRFiches = async () => {
      try {
        const fiches = await getAllFicheQR();
        setAllQRFiches(fiches);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
    fetchAllQRFiches();
  }, [navigate]);

  useEffect(() => {
    if (dbUser && allQRFiches.length > 0) {
      findQRCode();
    }
  }, [dbUser, allQRFiches]);

  const findQRCode = () => {
    const qrCode = allQRFiches.find(item => item.urlid === urlid);

    if (urlid === "1") {
      setFoundQR("Ça marche pas comme ça, espèce de petit rigolo. 👀");
      return;
    }

    if (!qrCode) {
      setFoundQR("Le code est invalide.");
      return;
    }

    if (dbUser.qrfound_sports.includes(qrCode.gameid)) {
      setFoundQR("Ce code a déjà été scanné.");
      return;
    }

    // Ajouter le code trouvé à la base de données
    const addQR = async () => {
      try {
        const res = await axios.put(`${API_URL}api/addQR`, {
          accountId: dbUser._id,
          username: dbUser.username,
          gameid: qrCode.gameid,
        });

        if (res.data.status === "Success") {
          console.log("Ajout du QR code effectué avec succès.");
          console.log("DATE: " + Date.now());
        } else {
          console.log("Impossible d'ajouter le QR code.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    addQR();

    setFoundQR(
      <div>
        <p>Vous avez trouvé un nouveau sportif !</p>
        <ul>
          <li key={qrCode.gameid} className={styles.list}>
            <>
              <img
                src={qrCode.image || 'img/notfound.png'}
                alt={qrCode.nom}
                className={styles.imgqr}
              />
              <p className={styles.listtitle}>
                {qrCode.gameid} - {qrCode.nom}
              </p>
              <p className={styles.listsport}>{qrCode.nationalite}</p>
              <p className={styles.listsport}>{qrCode.sport}</p>
              <p className={styles.listdesc}>{qrCode.description}</p>
            </>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className={styles.scanqrsport}>
      {foundQR}
    </div>
  );
}

export default ScanQRSport;
