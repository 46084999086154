import { useNavigate } from "react-router-dom";
import Title from "../../components/Title.js/Title";
import styles from "./MyAccount.module.scss"
import axios from "axios";
import { useEffect, useState } from "react";

import { API_URL } from "../../conf";
// import Title from "../../components/Title.js/Title";
// import { useState } from "react";
// import CardMember from "../../components/CardMember/CardMember";
// import Notification from "../../components/Notification/Notification";
//const API_URL = "http://localhost:3001/"
//const API_URL = "https://app.pictageek.fr/";


function MyAccount() {

    const [username, setUsername] = useState('')

    axios.defaults.withCredentials = true;
    const navigate = useNavigate()
    const handleLogout = () => {
        axios.get(`${API_URL}api/logout`)
        .then(res => {
            if(res.data === "Success") {
                navigate(0);
            }
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        axios.get(`${API_URL}api/getActualDBuser`) //vérifie si le token est valide donc si l'utilisateur est connecté.
        .then(result => {
          //console.log(result);
          if(result.data != "invalid") {
              //console.log(result.data);
              setUsername(result.data.username)
          } else {
            console.log("Non connecté");
            navigate('/connection')
          }
        })
        .catch(err => console.log(err))
    }, [])


    return (
        <div className={styles.MyAccount}>
            <Title text={"Mon Compte"}/>
            <div className={styles.accountInfo}>
                <div className={styles.accountItem}>
                    <div className={styles.label}></div>
                    <div className={styles.value}>Connecté en tant que : {username}</div>
                </div>
            </div>
            <div className={styles.logoutButton}>
                <button onClick={handleLogout}>Se déconnecter</button>
            </div>
        </div>
    );

}

export default MyAccount;
